@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
body {
  margin: 0;
  padding: 0;
 }





* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

#main {
  overflow: hidden;
  background-color: rgb(219, 209, 209);
}








/* #projectsWrapperOuter {
  
  width: 100%;
  text-align: center;
} */





#educationTimeline { 
  width: 50%;
  box-sizing: border-box;
  position: relative;
  display: block;
  overflow: auto;
  float: left;
  border: 2px solid darkgreen;
  border-top: 0px;
  height: 300px;
  flex: -1 1;
  margin: auto;
}

#educationTimeline::-webkit-scrollbar {
  display: none;
}

#educationTimeline::after {
  content : "";
  display: block;
  position: absolute;
  background-image: url('https://images.unsplash.com/photo-1523050854058-8df90110c9f1?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=352d3cdaa50b65c4adf76c1d4dd4ca7e&auto=format&fit=crop&w=1050&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  width: 100%;
  height: 100%;
  opacity : 0.2; 
}

#workTimeline {
  width: 50%;
  flex: -1 1;
  box-sizing: border-box;
  position: relative;
  display: block;
  overflow: auto;
  float: right;
  height: 300px;
}

#workTimeline::-webkit-scrollbar {
  display: none;
}

#educationTimeline::-webkit-scrollbar {
  display: none;
}

#workTimeline::after {
  content : "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('https://images.unsplash.com/photo-1508188609340-e8068b599193?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=bda590a01e9f9b62dde582f0f031b1cd&auto=format&fit=crop&w=700&q=80');
  background-size: cover;
  width: 100%;
  height: 700px;
  opacity : 0.2;
}

.scrollFaderTop {
  content:'';
  height: 40px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(251, 251, 251, 1)), to(rgba(251, 251, 251, 0)));
  background: -webkit-linear-gradient(top, rgba(251, 251, 251, 1) 0%, rgba(251, 251, 251, 0) 100%);
  background: linear-gradient(to bottom, rgba(251, 251, 251, 1) 0%, rgba(251, 251, 251, 0) 100%);
  position: relative;
}

.scrollFaderBottom {
  content:'';
  margin-top: 220px;
  height: 40px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(251, 251, 251, 1)), to(rgba(251, 251, 251, 0)));
  background: -webkit-linear-gradient(bottom, rgba(251, 251, 251, 1) 0%, rgba(251, 251, 251, 0) 100%);
  background: linear-gradient(to top, rgba(251, 251, 251, 1) 0%, rgba(251, 251, 251, 0) 100%);
  position: relative;
}

#contactMe {
  padding: 30px 0px 200px 0px;
  margin-top: -40px;
  height: 335px;;
  width: 100%;
}

#parallax {
    font-family: 'Rambla', sans-serif, Helvetica;
    color: hsl(0, 0%, 17%);
    text-shadow: -1px 0 whitesmoke, 0 1px whitesmoke, 1px 0 whitesmoke, 0 -1px whitesmoke;
    position: relative;
    font-size: 8vw;
    width: 100vw;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('https://images.unsplash.com/photo-1506710413-d5d6f022286d?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7f3efe5c170d9d0dab00ffaf872e5b7c&auto=format&fit=crop&w=1050&q=80'); 
  }

.languages {
    width: 70%;
    left: 2%;
    bottom: 24%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
}

.languages i {
    text-shadow: .2vw .1vw .1vw rgb(255, 255, 255);
    
}
.myFace {
    width: 22vw;
    height: 22vw;
    position: absolute;
    border: 1px solid whitesmoke;
    -webkit-transform: skew(-2deg) rotate(2deg);
            transform: skew(-2deg) rotate(2deg);
    bottom: 16vh;
    right: .7%;
    display: flex;
    z-index: 5;
    border-radius: 3px;         
    background-size: cover;
    overflow: hidden;
    
}
.myFace img {
    width: 110%;
    position: absolute;
    top: 67%;
    -webkit-transform: skew(2deg) rotate(-2deg) translateY(-50%);
            transform: skew(2deg) rotate(-2deg) translateY(-50%);
}
.aboutMe {
    position: absolute;
    bottom: 0vw;
    padding: 2vh 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dad878;
    }
    
.aboutMe p {
    display: inline-block;
    font: 4.2vw "Oleo Script", Helvetica, sans-serif;
    color: #2b2b2b;
    }

.hiddenNav{
    overflow: hidden;
    position: absolute;
    height: 8.5vh;
    visibility: hidden; 
    }

#navbar {
    overflow: hidden;
    background-color: rgb(9, 36, 25);
    z-index: 100;
    height: 8.5vh;
    border: .5vh solid black;
    width: 100%;
    -webkit-transition: opacity 2s;
    transition: opacity 2s;
    display: flex;
  }
  
  #navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  width: 100%;
  }
  #navbar ul li {
    display: flex;
    color: #ffffff;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
  #navbar ul li a {
    margin: auto;
    font-family: 'Rambla';
    font-size: 1.5vw;
    text-transform: uppercase;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    padding: .8vw 1vw; 
    border-radius: 5px;
    text-decoration: none;
    color: unset;
    cursor: pointer;
    
  }
  #navbar ul li a:hover {
    background-color: rgb(0, 0, 0);
      }

  .sticky {
    position: fixed;
    top: 0px;
    width: inherit;
    opacity: 0.95; 
    -webkit-transition: opacity 2s; 
    transition: opacity 2s;
    
  }
  
 

:root {
  --grid-top: 0px;
  --grid-left: 0px;
  --grid-width: 0px;
  --grid-height: 0px;

  --tile-top: 0px;
  --tile-left: 0px;
  --tile-width: 0px;
  --tile-height: 0px;

  --tileDivTitle-width: 0px;
  --tileDivTitle-height: 0px;
}

#projectsWrapper {
   height: 85vh;
  }

.contentWrapper{
    display: none;
}
.tile {
  position: absolute;
  border-radius: 5px;
  border: 2px black;
  text-align: right;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.expand {
  -webkit-animation: expand forwards 800ms ease-in-out;
          animation: expand forwards 800ms ease-in-out;
}

@-webkit-keyframes expand {
  0%   {}
  100% {top: 0px;top: var(--grid-top); left: 0px; left: var(--grid-left); width: 0px; width: var(--grid-width); height: 0px; height: var(--grid-height);}
}

@keyframes expand {
  0%   {}
  100% {top: 0px;top: var(--grid-top); left: 0px; left: var(--grid-left); width: 0px; width: var(--grid-width); height: 0px; height: var(--grid-height);}
}

.retract {
  -webkit-animation: retract forwards 800ms ease-in-out;
          animation: retract forwards 800ms ease-in-out;  
}

@-webkit-keyframes retract {
  0%   {top: 0px;top: var(--grid-top); left: 0px; left: var(--grid-left); width: 0px; width: var(--grid-width); height: 0px; height: var(--grid-height);}
  100% {top: 0px;top: var(--tile-top); left: 0px; left: var(--tile-left); width: 0px; width: var(--tile-width); height: 0px; height: var(--tile-height);}
}

@keyframes retract {
  0%   {top: 0px;top: var(--grid-top); left: 0px; left: var(--grid-left); width: 0px; width: var(--grid-width); height: 0px; height: var(--grid-height);}
  100% {top: 0px;top: var(--tile-top); left: 0px; left: var(--tile-left); width: 0px; width: var(--tile-width); height: 0px; height: var(--tile-height);}
}

.grid {
  display: grid;
  grid-template-columns: 25% 25% 2fr 2fr 4fr;
  grid-template-rows: repeat(3, 13vh);
  grid-gap: 1vh 1vh;
}

.unclicked {
    border: 2px white;
    height: 100%;;
    margin: auto;
    width: 95vw;
  }
  
  .a {
    grid-area: 1 / 1 / span 2 / span 2;
    background-image: url(/static/media/a-timeline.473b3032.png);
    background-size: cover;
  }
  .b {
    grid-area: 1 / 3 / span 2 / span 2;
    background-color: rgb(100, 100, 93);
    background-image: url(/static/media/b-AnimalSounds.1da93f89.png);
    background-size: cover;
    
  }
  .c {
    grid-area: 1 / 5 / span 2 / span 1;
    background-color: rgb(83, 83, 13);
    background-image: url(/static/media/c-RecipeApp.213d1270.png);
    background-size: cover;
  }
  .d {
    grid-area: 3 / 1 / span 2 / span 1;
    background-color: rgb(247, 247, 99);
    background-image: url(/static/media/d-OCEAN.53488488.png);
    background-size: cover;
  }
  .e {
    grid-area: 3 / 2 / span 1 / span 2;
    background-color: rgb(236, 236, 236);
    background-image: url(/static/media/e-TypeaheadSearchbar.687847d5.png);
    background-size: cover;
  }
  .f {
    grid-area: 4 / 2 / span 1 / span 2;
    background-color: rgb(212, 212, 18);
    background-image: url('https://images.unsplash.com/photo-1516796181074-bf453fbfa3e6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80');
    background-size: cover;
  }

  .g {
    grid-area: 3 / 4 / span 2 / span 2;
    background-color: rgb(212, 212, 18);
    background-image: url('https://images.unsplash.com/photo-1531750985903-9018577a2472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80');
    background-size: cover;
  }

  .hidden {
    display: none;
  }

  .box {
    color: black;
    position: relative;
    border-radius: 5px;
    border: 2px black;
    text-align: right;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-transition-duration: 200ms;
            transition-duration: 200ms;
  }

  .box:hover {
    box-shadow: 0 2px 28px rgba(0,0,0,0.25), 0 5px 6px rgba(0,0,0,0.22);
  }

  .tileInfo {
    color: rgb(22, 18, 18);
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(245, 245, 245, 0.9);
    font: 400 1.6vw 'Rambla', Helvetica, sans-serif;
    text-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    position: absolute;
    top: 3vh;
    left: 2vw;
    -webkit-transition: width 200ms ease-out;
    transition: width 200ms ease-out;
    text-align: left;
  }

  .projectTitle {
    
   
    white-space: nowrap;
  }
  
  .infoExpand {
    -webkit-animation: infoExpand forwards 500ms ease-in-out;
            animation: infoExpand forwards 500ms ease-in-out;
  }

  @-webkit-keyframes infoExpand {
    0%  {width: 0px;width: var(--tileDivTitle-width); height: 0px; height: var(--tileDivTitle-height);}
    100% {width: 30vw; height: 46.5vh;}
  }

  @keyframes infoExpand {
    0%  {width: 0px;width: var(--tileDivTitle-width); height: 0px; height: var(--tileDivTitle-height);}
    100% {width: 30vw; height: 46.5vh;}
  }

  .infoRetract {
    -webkit-animation: infoRetract forwards 500ms ease-in-out;
            animation: infoRetract forwards 500ms ease-in-out;
  }

  @-webkit-keyframes infoRetract {
    0% {width: 30vw; height: 46.5vh;}
    100%  {width: 0px;width: var(--tileDivTitle-width); height: 0px; height: var(--tileDivTitle-height);}
  }

  @keyframes infoRetract {
    0% {width: 30vw; height: 46.5vh;}
    100%  {width: 0px;width: var(--tileDivTitle-width); height: 0px; height: var(--tileDivTitle-height);}
  }

  .tileText {
    font-family: 'Rambla';
    font-size: 1.323vw;
    color: black;
  }

  .viewDiv {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
    width: 100%;
    font-size: 5vw;
    z-index: 30;
    }

  .viewProject {
    color: rgb(90, 115, 196);
    border: solid rgb(90, 115, 196) 4px;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    border-radius: 20px;
    margin: auto;
    text-decoration: none;
    z-index: 31;
    padding: 0 1vw 2vh 1vw;
  }

  .viewProject:hover {
    background-color: rgb(245, 245, 245, 0.5);
    color: rgb(17, 56, 185);
    border: solid rgb(17, 56, 185) 4px;
  }

  .svgShow {
    margin: auto;
    display: block;
    position: absolute;
    top: 65px;  
    pointer-events: none;
    opacity: 1;
    -webkit-animation: fadeInOpacity 1s forwards ease-out;
            animation: fadeInOpacity 1s forwards ease-out;
  }
  
  .fadeInOpacity {
    -webkit-animation: fadeInOpacity 500ms forwards ease-out;
            animation: fadeInOpacity 500ms forwards ease-out;
  }
  
  @-webkit-keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 
  
  .skillbar {
      position:relative;
      
      width: 90%;
      left: 5vw;
      right: 5vw;
      background:#eee;
      height: 7vh;
      border-radius:3px;
      -moz-border-radius:3px;
      -webkit-border-radius:3px;
  }
  
  .skillbar:not(:last-of-type) {
    margin-bottom:30px;
  }

 

  .skillbar-title {
      position:absolute;
      top:0;
      left:0;
      width:11vw;
      font-weight:bold;
      font-size:1.5vw;
      color:#ffffff;
      background:#6adcfa;
      -webkit-border-top-left-radius:3px;
      -webkit-border-bottom-left-radius:4px;
      -moz-border-radius-topleft:3px;
      -moz-border-radius-bottomleft:3px;
      border-top-left-radius:3px;
      border-bottom-left-radius:3px;
      display: flex;
      justify-content: center;
      align-items: center;
    
      
    }
    
    .skillbar-title span {
      background:rgba(0, 0, 0, 0.1);
      height:7vh;
      left: 5vw;
      width: 100%;
      padding-left: 15px;
      -webkit-border-top-left-radius:3px;
      -webkit-border-bottom-left-radius:3px;
      -moz-border-radius-topleft:3px;
      -moz-border-radius-bottomleft:3px;
      border-top-left-radius:3px;
      display: flex;
      justify-content: left;
      align-items: center;
      border-bottom-left-radius:3px;
  }

  .skillbar-title span i {
    position: relative;
    font-size: 5.5vh;
    left: -.3vw;
  }
  
  .skillbar-bar {
    -webkit-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
    height:100%;
    width:0%;
    background:#6adcfa;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
  }
  
  .skill-bar-percent {
      position:absolute;
      right:10px;
      top:0;
      font-size:25px;
      height:35px;
      line-height:50px;
      color:#ffffff;
      color:rgba(0, 0, 0, 0.4);
  }
.contact .fa:before {
  display: inline-block;
  opacity: 0.7;
}

.contact li {
  font-family: "Segoe UI", Helvetica Neue, Tahoma, sans-serif;
  font-size: 1.6vw;
  display: inline-block;
  list-style-type: none;
  margin: 0 2vw;
  text-align: center;
  color: unset;
}

.contact p {
  text-align: center;
}

.contact {
  display: inline-block;
  margin: 0 auto;
  padding-left: 0;
}

.cont {
  height: 100vw;
  width: 100%;
  text-align: center;
}
.recipe_recipe__2is2O {
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgb(88, 84, 84);
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: white;
    align-items: center;
    min-width: 40%;
}

.recipe_image__17Ko7 {
    border-radius: 3px;
    height: 40vh;
    margin-bottom: 5vh;
  
}

.recipe_list__2Ajce > * {
    max-width: 30vw;
}

.recipe_dietLabels__1QELF {
    font-size: 1.5vw;
    margin: 0;
}
.App {
  min-height: 100vh;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fbc2eb), to(#a6c1ee));
  background-image: -webkit-linear-gradient(bottom, #fbc2eb 0%, #a6c1ee 100%);
  background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.search-form {
  min-height: 10vh;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar {
  width: 50%;
  border: none;
  background-color: white;  
  padding: 10px;
}

.search-button {
  background-color: lightcoral;
  border: none;
  padding: 10px 20px;
  color: white;
}

.recipes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
