.recipe {
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgb(88, 84, 84);
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: white;
    align-items: center;
    min-width: 40%;
}

.image {
    border-radius: 3px;
    height: 40vh;
    margin-bottom: 5vh;
  
}

.list > * {
    max-width: 30vw;
}

.dietLabels {
    font-size: 1.5vw;
    margin: 0;
}