#parallax {
    font-family: 'Rambla', sans-serif, Helvetica;
    color: hsl(0, 0%, 17%);
    text-shadow: -1px 0 whitesmoke, 0 1px whitesmoke, 1px 0 whitesmoke, 0 -1px whitesmoke;
    position: relative;
    font-size: 8vw;
    width: 100vw;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('https://images.unsplash.com/photo-1506710413-d5d6f022286d?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7f3efe5c170d9d0dab00ffaf872e5b7c&auto=format&fit=crop&w=1050&q=80'); 
  }

.languages {
    width: 70%;
    left: 2%;
    bottom: 24%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
}

.languages i {
    text-shadow: .2vw .1vw .1vw rgb(255, 255, 255);
    
}
.myFace {
    width: 22vw;
    height: 22vw;
    position: absolute;
    border: 1px solid whitesmoke;
    transform: skew(-2deg) rotate(2deg);
    bottom: 16vh;
    right: .7%;
    display: flex;
    z-index: 5;
    border-radius: 3px;         
    background-size: cover;
    overflow: hidden;
    
}
.myFace img {
    width: 110%;
    position: absolute;
    top: 67%;
    transform: skew(2deg) rotate(-2deg) translateY(-50%);
}
.aboutMe {
    position: absolute;
    bottom: 0vw;
    padding: 2vh 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dad878;
    }
    
.aboutMe p {
    display: inline-block;
    font: 4.2vw "Oleo Script", Helvetica, sans-serif;
    color: #2b2b2b;
    }