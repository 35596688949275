
.hiddenNav{
    overflow: hidden;
    position: absolute;
    height: 8.5vh;
    visibility: hidden; 
    }

#navbar {
    overflow: hidden;
    background-color: rgb(9, 36, 25);
    z-index: 100;
    height: 8.5vh;
    border: .5vh solid black;
    width: 100%;
    transition: opacity 2s;
    display: flex;
  }
  
  #navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  width: 100%;
  }
  #navbar ul li {
    display: flex;
    color: #ffffff;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
  #navbar ul li a {
    margin: auto;
    font-family: 'Rambla';
    font-size: 1.5vw;
    text-transform: uppercase;
    transition: all 500ms;
    padding: .8vw 1vw; 
    border-radius: 5px;
    text-decoration: none;
    color: unset;
    cursor: pointer;
    
  }
  #navbar ul li a:hover {
    background-color: rgb(0, 0, 0);
      }

  .sticky {
    position: fixed;
    top: 0px;
    width: inherit;
    opacity: 0.95; 
    transition: opacity 2s;
    
  }
  
 