
:root {
  --grid-top: 0px;
  --grid-left: 0px;
  --grid-width: 0px;
  --grid-height: 0px;

  --tile-top: 0px;
  --tile-left: 0px;
  --tile-width: 0px;
  --tile-height: 0px;

  --tileDivTitle-width: 0px;
  --tileDivTitle-height: 0px;
}

#projectsWrapper {
   height: 85vh;
  }

.contentWrapper{
    display: none;
}
.tile {
  position: absolute;
  border-radius: 5px;
  border: 2px black;
  text-align: right;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.expand {
  animation: expand forwards 800ms ease-in-out;
}

@keyframes expand {
  0%   {}
  100% {top: var(--grid-top); left: var(--grid-left); width: var(--grid-width); height: var(--grid-height);}
}

.retract {
  animation: retract forwards 800ms ease-in-out;  
}

@keyframes retract {
  0%   {top: var(--grid-top); left: var(--grid-left); width: var(--grid-width); height: var(--grid-height);}
  100% {top: var(--tile-top); left: var(--tile-left); width: var(--tile-width); height: var(--tile-height);}
}

.grid {
  display: grid;
  grid-template-columns: 25% 25% 2fr 2fr 4fr;
  grid-template-rows: repeat(3, 13vh);
  grid-gap: 1vh 1vh;
}

.unclicked {
    border: 2px white;
    height: 100%;;
    margin: auto;
    width: 95vw;
  }
  
  .a {
    grid-area: 1 / 1 / span 2 / span 2;
    background-image: url('../images/a-timeline.png');
    background-size: cover;
  }
  .b {
    grid-area: 1 / 3 / span 2 / span 2;
    background-color: rgb(100, 100, 93);
    background-image: url('../images/b-AnimalSounds.png');
    background-size: cover;
    
  }
  .c {
    grid-area: 1 / 5 / span 2 / span 1;
    background-color: rgb(83, 83, 13);
    background-image: url('../images/c-RecipeApp.png');
    background-size: cover;
  }
  .d {
    grid-area: 3 / 1 / span 2 / span 1;
    background-color: rgb(247, 247, 99);
    background-image: url('../images/d-OCEAN.png');
    background-size: cover;
  }
  .e {
    grid-area: 3 / 2 / span 1 / span 2;
    background-color: rgb(236, 236, 236);
    background-image: url('../images/e-TypeaheadSearchbar.png');
    background-size: cover;
  }
  .f {
    grid-area: 4 / 2 / span 1 / span 2;
    background-color: rgb(212, 212, 18);
    background-image: url('https://images.unsplash.com/photo-1516796181074-bf453fbfa3e6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80');
    background-size: cover;
  }

  .g {
    grid-area: 3 / 4 / span 2 / span 2;
    background-color: rgb(212, 212, 18);
    background-image: url('https://images.unsplash.com/photo-1531750985903-9018577a2472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80');
    background-size: cover;
  }

  .hidden {
    display: none;
  }

  .box {
    color: black;
    position: relative;
    border-radius: 5px;
    border: 2px black;
    text-align: right;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition-duration: 200ms;
  }

  .box:hover {
    box-shadow: 0 2px 28px rgba(0,0,0,0.25), 0 5px 6px rgba(0,0,0,0.22);
  }

  .tileInfo {
    color: rgb(22, 18, 18);
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(245, 245, 245, 0.9);
    font: 400 1.6vw 'Rambla', Helvetica, sans-serif;
    text-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    position: absolute;
    top: 3vh;
    left: 2vw;
    transition: width 200ms ease-out;
    text-align: left;
  }

  .projectTitle {
    
   
    white-space: nowrap;
  }
  
  .infoExpand {
    animation: infoExpand forwards 500ms ease-in-out;
  }

  @keyframes infoExpand {
    0%  {width: var(--tileDivTitle-width); height: var(--tileDivTitle-height);}
    100% {width: 30vw; height: 46.5vh;}
  }

  .infoRetract {
    animation: infoRetract forwards 500ms ease-in-out;
  }

  @keyframes infoRetract {
    0% {width: 30vw; height: 46.5vh;}
    100%  {width: var(--tileDivTitle-width); height: var(--tileDivTitle-height);}
  }

  .tileText {
    font-family: 'Rambla';
    font-size: 1.323vw;
    color: black;
  }

  .viewDiv {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    font-size: 5vw;
    z-index: 30;
    }

  .viewProject {
    color: rgb(90, 115, 196);
    border: solid rgb(90, 115, 196) 4px;
    transition: all 500ms;
    border-radius: 20px;
    margin: auto;
    text-decoration: none;
    z-index: 31;
    padding: 0 1vw 2vh 1vw;
  }

  .viewProject:hover {
    background-color: rgb(245, 245, 245, 0.5);
    color: rgb(17, 56, 185);
    border: solid rgb(17, 56, 185) 4px;
  }

  .svgShow {
    margin: auto;
    display: block;
    position: absolute;
    top: 65px;  
    pointer-events: none;
    opacity: 1;
    animation: fadeInOpacity 1s forwards ease-out;
  }
  
  .fadeInOpacity {
    animation: fadeInOpacity 500ms forwards ease-out;
  }
  
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 