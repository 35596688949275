@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css");


.contact .fa:before {
  display: inline-block;
  opacity: 0.7;
}

.contact li {
  font-family: "Segoe UI", Helvetica Neue, Tahoma, sans-serif;
  font-size: 1.6vw;
  display: inline-block;
  list-style-type: none;
  margin: 0 2vw;
  text-align: center;
  color: unset;
}

.contact p {
  text-align: center;
}

.contact {
  display: inline-block;
  margin: 0 auto;
  padding-left: 0;
}

.cont {
  height: 100vw;
  width: 100%;
  text-align: center;
}