  
  .skillbar {
      position:relative;
      
      width: 90%;
      left: 5vw;
      right: 5vw;
      background:#eee;
      height: 7vh;
      border-radius:3px;
      -moz-border-radius:3px;
      -webkit-border-radius:3px;
  }
  
  .skillbar:not(:last-of-type) {
    margin-bottom:30px;
  }

 

  .skillbar-title {
      position:absolute;
      top:0;
      left:0;
      width:11vw;
      font-weight:bold;
      font-size:1.5vw;
      color:#ffffff;
      background:#6adcfa;
      -webkit-border-top-left-radius:3px;
      -webkit-border-bottom-left-radius:4px;
      -moz-border-radius-topleft:3px;
      -moz-border-radius-bottomleft:3px;
      border-top-left-radius:3px;
      border-bottom-left-radius:3px;
      display: flex;
      justify-content: center;
      align-items: center;
    
      
    }
    
    .skillbar-title span {
      background:rgba(0, 0, 0, 0.1);
      height:7vh;
      left: 5vw;
      width: 100%;
      padding-left: 15px;
      -webkit-border-top-left-radius:3px;
      -webkit-border-bottom-left-radius:3px;
      -moz-border-radius-topleft:3px;
      -moz-border-radius-bottomleft:3px;
      border-top-left-radius:3px;
      display: flex;
      justify-content: left;
      align-items: center;
      border-bottom-left-radius:3px;
  }

  .skillbar-title span i {
    position: relative;
    font-size: 5.5vh;
    left: -.3vw;
  }
  
  .skillbar-bar {
    transition: all 0.7s ease-in-out;
    height:100%;
    width:0%;
    background:#6adcfa;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
  }
  
  .skill-bar-percent {
      position:absolute;
      right:10px;
      top:0;
      font-size:25px;
      height:35px;
      line-height:50px;
      color:#ffffff;
      color:rgba(0, 0, 0, 0.4);
  }